import React from "react";
import { defaults } from "react-chartjs-2";
import Dashboard from "./dashboard";

defaults.global.defaultFontFamily = "ProximaNovaRegular";

// https://serverless-stack.com/chapters/configure-aws-amplify.html

const IndexContainer = ({ location }) => {
  return <Dashboard location={location} />;
};
export default IndexContainer;
